import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import bookingSourceImages from '../../GuestPortal/images';
import bookingSourceColors from '../../GuestPortal/colors';
import getTextContrastColor from '../../../libs/textContrastColor';
import type {KioskOverlayProps} from './types/KioskOverlay';
import type {SlideProps} from '@material-ui/core/Slide';

const useStyles = (backgroundColor: string) =>
    makeStyles((theme) => ({
        dialog: {
            backgroundColor: backgroundColor,
            textAlign: 'center',
            position: 'relative',
        },
        root: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            color: getTextContrastColor(backgroundColor),
            width: '100%',
        },
        logo: {
            maxWidth: '80%',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(4),
        },
        largeText: {
            fontSize: 'xx-large',
        },
        xlargeText: {
            fontSize: 'xxx-large',
        },
    }));

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const KioskOverlay: React.FC<KioskOverlayProps> = ({open, branding, onClose}) => {
    const logoSource = bookingSourceImages(branding, true);
    const backgroundColor = bookingSourceColors(branding);
    const classes = useStyles(backgroundColor)();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            classes={{paper: classes.dialog}}
            PaperProps={{onClick: onClose}}
            fullScreen
        >
            <div className={classes.root}>
                <Typography variant="h3" color="inherit" className={classes.largeText} gutterBottom>
                    Welcome to
                </Typography>

                {logoSource && <img src={logoSource} className={classes.logo} />}
                {!logoSource && (
                    <Typography variant="h1" color="inherit" className={classes.xlargeText} gutterBottom>
                        {branding.company?.name}
                    </Typography>
                )}

                <Typography variant="h4" color="inherit" gutterBottom>
                    Touch anywhere to begin
                </Typography>
            </div>
        </Dialog>
    );
};

export default KioskOverlay;
