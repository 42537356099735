import React from 'react';
import {reduxForm, Field, SubmissionError} from 'redux-form';
import compose from 'recompose/compose';
import classNames from 'classnames';
import {useTranslations} from 'next-intl';

// form validation
import {SyncValidation as validate} from '../validation';

// Utils
import {searchReservation} from '../utils';
import sendSegment from '../../../libs/segment';
import {getOrNull} from '../../../libs/utils';

// custom components
import {Spacing} from '../../../components/Styles';
import ErrorBox from '../../../components/ErrorBox/ErrorBox';
import TextField from '../../../components/Input/TextField';

// Material-UI components
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

// styles
import {makeStyles} from '@material-ui/core/styles';

import type {SearchFormProps} from './types/SearchForm';
import type {Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) => ({
    root: {
        display: 'block',
    },
}));

const SearchForm: React.FC<SearchFormProps> = ({
    className,
    hostname,
    reservation,
    notFound,
    handleSubmit,
    onSuccess,
    error,
    submitting,
    pristine,
    invalid,
    useFullForm,
}) => {
    const classes = useStyles();
    const t = useTranslations('components.SearchForm');
    const [searchError, setSearchError] = React.useState<boolean | null>(null);
    const [showFullForm, setShowFullForm] = React.useState(Boolean(useFullForm));

    // React.useEffect(() => {
    //     autofill('date', moment().format('YYYY-MM-DD'));
    // }, []);

    const handleSearch = async (form: {}) => {
        try {
            const res = await searchReservation({...form, hostname});
            if (res && res.data) {
                sendSegment('track', {
                    name: 'Guest Found Reservation via Search',
                    data: {...form, reservation: res.data.id, hostname},
                });
                onSuccess(res.data.id);
            }
        } catch (e) {
            sendSegment('track', {
                name: 'Guest Did Not Find Reservation via Search',
                data: {...form, error: e, hostname},
            });
            setSearchError(true);
            setShowFullForm(true);
            throw new SubmissionError({_error: e});
        }
    };

    const emailContact = getOrNull('company.email', reservation);
    const phoneContact = getOrNull('company.phone', reservation);
    let searchErrorKey = 'search_error_text';
    let contact = emailContact || phoneContact;
    if (emailContact || phoneContact) {
        searchErrorKey = 'search_error_text_contact';
    }
    if (emailContact && phoneContact) {
        searchErrorKey = 'search_error_text_contact_both';
    }

    return (
        <div className={classNames(classes.root, className)}>
            {notFound && (
                <React.Fragment>
                    <Typography variant="h2" gutterBottom>
                        {t('notfound')}
                    </Typography>
                    <Spacing margin="40px" />
                </React.Fragment>
            )}

            <Typography variant="h4" gutterBottom>
                {t('title')}
            </Typography>
            <Spacing margin="15px" />

            {error && (
                <React.Fragment>
                    <ErrorBox error={error} contentProps={{align: 'left', padding: '0'}} />
                    <Spacing margin="20px" />
                </React.Fragment>
            )}

            {searchError && (
                <React.Fragment>
                    <Spacing margin="15" />
                    <Alert severity="info">
                        <AlertTitle>{t('search_error_title')}</AlertTitle>
                        {t(searchErrorKey, {
                            phone: phoneContact,
                            email: emailContact,
                            contact,
                            strong: (children) => <strong>{children}</strong>,
                            span: (children) => <span>{children}</span>,
                        })}
                    </Alert>
                    <Spacing margin="20px" />
                </React.Fragment>
            )}

            <form onSubmit={handleSubmit((form: {}) => handleSearch(form))}>
                {Boolean(showFullForm) && (
                    <React.Fragment>
                        <Typography variant="subtitle1" gutterBottom>
                            {t('firstname')}
                        </Typography>
                        <Field
                            name="first_name"
                            type="text"
                            variant="outlined"
                            component={TextField}
                            fullWidth={true}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                        />
                        <Spacing margin="20" />
                    </React.Fragment>
                )}

                <Typography variant="subtitle1" gutterBottom>
                    {t('lastname')}
                </Typography>
                <Field
                    name="last_name"
                    type="text"
                    variant="outlined"
                    component={TextField}
                    fullWidth={true}
                    inputProps={{
                        autoComplete: 'new-password',
                    }}
                />
                <Spacing margin="20" />

                <Typography variant="subtitle1" gutterBottom>
                    {t('check_in_date')}
                </Typography>
                <Field
                    name="check_in_date"
                    type="date"
                    variant="outlined"
                    component={TextField}
                    fullWidth={true}
                    inputProps={{
                        autoComplete: 'new-password',
                    }}
                />

                {Boolean(showFullForm) && (
                    <React.Fragment>
                        <Spacing margin="20" />
                        <Typography variant="subtitle1" gutterBottom>
                            {t('check_out_date')}
                        </Typography>
                        <Field
                            name="check_out_date"
                            type="date"
                            variant="outlined"
                            component={TextField}
                            fullWidth={true}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                        />
                    </React.Fragment>
                )}

                {/*{Boolean(searchError) &&*/}
                {/*<React.Fragment>*/}
                {/*    <Spacing margin="20"/>*/}
                {/*    <Typography*/}
                {/*        variant="subtitle1"*/}
                {/*        gutterBottom*/}
                {/*    >*/}
                {/*        {t('bookingref')}*/}
                {/*    </Typography>*/}
                {/*    <Field*/}
                {/*        name="confirmation_code"*/}
                {/*        type="text"*/}
                {/*        variant="outlined"*/}
                {/*        component={TextField}*/}
                {/*        fullWidth={true}*/}
                {/*        inputProps={{*/}
                {/*            autoComplete: 'new-password',*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <FormHelperText>{t('bookingrefhelp')}</FormHelperText>*/}
                {/*</React.Fragment>*/}
                {/*}*/}

                {/*{Boolean(searchError) &&*/}
                {/*<React.Fragment>*/}
                {/*    <Spacing margin="20"/>*/}
                {/*    <Typography*/}
                {/*        variant="subtitle1"*/}
                {/*        gutterBottom*/}
                {/*    >*/}
                {/*        {t('email')}*/}
                {/*    </Typography>*/}
                {/*    <Field*/}
                {/*        name="email"*/}
                {/*        type="email"*/}
                {/*        variant="outlined"*/}
                {/*        component={TextField}*/}
                {/*        fullWidth={true}*/}
                {/*        inputProps={{*/}
                {/*            autoComplete: 'new-password',*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</React.Fragment>*/}
                {/*}*/}

                <Spacing />
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth={true}
                    disabled={Boolean(submitting) || Boolean(pristine) || Boolean(invalid)}
                >
                    {t('primaryButton')}
                </Button>
            </form>
        </div>
    );
};

const ReduxForm = {
    form: 'search',
    validate,
};

export default compose<SearchFormProps, {}>(reduxForm(ReduxForm))(SearchForm);
