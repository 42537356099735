import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Content} from '../Styles';
import type {ErrorBoxProps} from './types/ErrorBox';

const ErrorBox: React.FC<ErrorBoxProps> = ({error, contentProps, ...other}) => {
    return (
        <Content {...contentProps}>
            <Typography variant="subtitle1" color="error" gutterBottom {...other}>
                {error}
            </Typography>
        </Content>
    );
};

export default ErrorBox;
