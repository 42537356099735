import apiClient from '../../libs/apiClient';

export const getBranding = async (domain:string) => {
    try {
        return await apiClient({path: `/guestportal/branding/${domain}`, method: 'GET'})
    } catch (e) {
        throw (e.response && e.response.data) || e
    }
};
export const findReservation = async (code:string) => {
    try {
        return await apiClient({path: `/guestportal/reservation/${code}`, method: 'GET'})
    } catch (e) {
        throw (e.response && e.response.data) || e
    }
};
export const searchReservation = async (data:{}) => {
    try {
        return await apiClient({path: `/guestportal/search`, method: 'POST', data})
    } catch (e) {
        throw (e.response && e.response.data) || e
    }
};