import {createValidator, required, email, isNotBeforeToday} from '../../libs/validation';

const SyncValidation = createValidator({
    date: [required, isNotBeforeToday],
    check_in_date: [required, isNotBeforeToday],
    check_out_date: [required, isNotBeforeToday],
    last_name: [required],
    first_name: [required],
    confirmation_code: [],
    email: [email],
});

export {
    SyncValidation,
};