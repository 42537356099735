import React from 'react';
import {useRouter} from 'next/router';
import Head from 'next/head';

// API client
import {getBranding} from './utils';

// custom components
import NotFound from '../GuestPortal/components/NotFound';
import bookingSourceImages from '../GuestPortal/images';
import bookingSourceColors from '../GuestPortal/colors';
import SearchForm from './components/SearchForm';
import Loading from '../../components/Loading';
import KioskOverlay from './components/KioskOverlay';

// layout
import Layout from '../../layouts/GuestPortal';

// Material-UI styles
import {makeStyles} from '@material-ui/core/styles';
import styles from '../GuestPortal/styles';
import {connect} from 'react-redux';

import type {SearchProps, StylesType} from './types/index';
import type {NextPage, NextPageContext} from 'next';
import type {ReduxState} from '../../store/redux.types';

const useStyles = makeStyles(styles as StylesType);

const Search: NextPage<SearchProps> = (props) => {
    props = props || {};
    const data = props.reservation;
    const router = useRouter();
    const classes = useStyles();
    const isKiosk = Boolean(router.query.kiosk);
    const [showOverlay, setShowOverlay] = React.useState(isKiosk);
    const [loading, setLoading] = React.useState(false);
    const [reservation, setReservation] = React.useState(data);
    const setError = React.useState<any>(null)[1];

    let hostname: string | undefined = undefined;
    if (reservation && reservation.domain) {
        hostname = reservation.domain;
    }
    if (!hostname && typeof location !== 'undefined') {
        hostname = location.hostname;
    }

    const invalidHostname = hostname && ['snapcheck.in', 'verifyhub.org', 'localhost'].includes(hostname);
    // load customer branding data
    React.useEffect(() => {
        if (!reservation && hostname && !invalidHostname) {
            setLoading(true);
            getBranding(hostname)
                .then((res) => {
                    if (res && res.data) {
                        setReservation(res.data);
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [hostname]);

    // hide or show 'feedback' tab
    React.useEffect(() => {
        const Userback = typeof window !== 'undefined' && window.Userback;
        if (!hostname || !reservation || invalidHostname) {
            return;
        }
        try {
            if (showOverlay && Userback && props.userbackIsLoaded) Userback?.hide?.();
            if (!showOverlay && Userback && props.userbackIsLoaded) Userback?.show?.();
        } catch (e) {
            console.error(e);
        }
    }, [showOverlay, props.userbackIsLoaded]);

    const handleSuccess = (id: string) => {
        // window.location = `/${id}`
        router.push(`/${id}${isKiosk ? '?kiosk=1' : ''}`);
    };

    // show loading screen
    if (loading && !reservation) {
        return <Loading />;
    }

    // show not found page
    if (!hostname || !reservation || invalidHostname) {
        return <NotFound router={router} />;
    }

    const notFound = router && router.query && router.query.id;
    const bookingSourceImage = bookingSourceImages(reservation, true);
    const bookingSourceImage2 = bookingSourceImages(reservation);
    const formProps = {
        useFullForm: true,
        className: classes.form,
        onSuccess: handleSuccess,
        notFound,
        hostname,
        reservation,
    };

    return (
        <Layout
            classes={{}}
            reservation={reservation}
            color={bookingSourceColors(reservation)}
            bookingSourceImage={bookingSourceImage || undefined}
            isMobile={true}
            paintBackground={false}
            hideTopbar={false}
            useScroll={false}
            onBack={false}
            onInfo={false}
        >
            <Head>
                <title>Guest Portal | {reservation.host_name}</title>
                <meta property="og:title" content={reservation.host_name || undefined} />
                <meta name="description" content={`Check-in for your reservation online`} />
                <meta property="og:description" content={`Check-in for your reservation online`} />
                {bookingSourceImage2 && (
                    <meta property="og:image" content={`https:${bookingSourceImage2.replace(/^https?:/, '')}`} />
                )}
                {bookingSourceImage2 && (
                    <meta
                        property="og:image:secure_url"
                        content={`https:${bookingSourceImage2.replace(/^https?:/, '')}`}
                    />
                )}
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            </Head>

            <div className={classes.root}>
                <SearchForm {...formProps} />
            </div>

            <KioskOverlay branding={reservation} open={showOverlay} onClose={() => setShowOverlay(false)} />
        </Layout>
    );
};

Search.getInitialProps = async ({req}: NextPageContext) => {
    let hostname: string | undefined;
    if (req && req.headers) {
        hostname = req.headers.host;
        if (hostname && hostname.includes(':')) {
            hostname = req.headers.host?.split(':')[0];
        }
    }

    let error, reservation;
    if (hostname && !['snapcheck.in', 'verifyhub.org'].includes(hostname)) {
        try {
            const res = await getBranding(hostname);
            if (res && res.data) {
                reservation = res.data;
            }
        } catch (e) {
            error = e;
            console.error(error);
        }
    }

    return {reservation};
};

const mapStateToProps = (state: ReduxState) => {
    return {userbackIsLoaded: state.guestportal.userbackIsLoaded};
};

const reduxConnect = connect(mapStateToProps, {});
export default reduxConnect(Search);
